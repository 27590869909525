export const sliders = {
  
    model: {
      create: {
        url: "admin/slider/create",
        method: "POST",
        data: null,
      },
      update: {
        url: "admin/slider/update/",
        method: "PUT",
        id: null,
        data: null,
      },
      get: {
        url: "admin/slider/getAll",
        method: "GET",
        id: null,
        params: null,
      },
      search: {
        url: "admin/slider/find/",
        method: "GET",
        id: null,
        params: null,
      },
      delete: {
        url: "admin/slider/delete/",
        method: "DELETE",
        id: null,
        params: null,
      },
    },
  };
  