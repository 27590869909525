<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12" v-if="loading">
        <div style="text-align: center" class="mt-5 mb-5">
          <pulse-loader color="#505d69" :loading="true"></pulse-loader>
        </div>
      </div>
      <div class="col-lg-12" v-else>
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Slider Info - {{ projectNumber }}</h4>
            </b-card-title>

            <div class="row">
              <div class="col-6">
                <FormulateForm
                  name="createManufacture"
                  v-model="values"
                  :schema="searchScheme"
                  @submit="submitHandler"
                >
                  <FormulateInput type="submit">
                    <span v-if="performAction">
                      <b-spinner class="vueformulate-loader"></b-spinner>
                      <span> Updating... </span>
                    </span>
                    <span v-else>Update Slider URL</span>
                  </FormulateInput>
                </FormulateForm>
              </div>
              <div class="col-6">
                <img class="project-images" :src="values.image" alt="" />
                <FormulateForm name="createManufacture" @submit="updateImage"
                  ><FormulateInput
                    type="image"
                    name="image"
                    validation="mime:image/jpeg,image/png,image/gif,image/webp"
                    label="Select your Image to upload"
                    help="Please png/ jpg file and then upload"
                  >
                  </FormulateInput>
                  <FormulateInput type="submit">
                    <span v-if="updatingImage">
                      <b-spinner class="vueformulate-loader"></b-spinner>
                      <span> Updating... </span>
                    </span>
                    <span v-else>Update Image</span>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <a
              class="btn btn-success"
              :href="'/edit-slider-details?id=' + $route.query.id"
              >Update Slider Gallery</a
            >

            <div class="row">
              <div class="col-3">
                <!-- <FormulateForm
                  name="createManufacture"
                  v-model="values"
                  :schema="exScheme"
                  :options="allProjects"
                  @submit="updateExInfo"
                >
                  <FormulateInput 
                    :schema="exScheme"
                    name="project_ids[]"
                    :options="allProjects"
                    type="checkbox"
                    >
                    <span v-if="updatingExInfo">
                      <b-spinner class="vueformulate-loader"></b-spinner>
                      <span> Updating... </span>
                    </span>
                    <span v-else>Update Slider</span>
                  </FormulateInput>
                </FormulateForm> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Settings</h4>
            </b-card-title>
            <div class="row">
              <div class="col-12">
                <b-button variant="danger" @click="deleteSlider" class="mr-4"
                  >Delete</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template> 
<script>
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { sliders } from "@/config/api/sliders";
import { projects } from "@/config/api/projects";

/**
 * Add Project Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      loading: false,
      title: "Sliders",
      items: [
        {
          text: "Slider Details",
          to: { path: "/slider-info", query: { id: this.$route.query.id } },
        },
        {
          text: "Edit Slider",
          active: true,
        },
      ],
      projectNumber: "",
      performAction: false,
      updatingImage: false,
      updatingAdvInfo: false,
      updatingExInfo: false,
      values: {},
      searchResult: undefined,
      allProjects: [],
      searchScheme: [
        {
          index: 0,
          type: "text",
          name: "url",
          label: "URL",
          disabled: false,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.$route.query.id) {
      this.projectNumber = this.$route.query.id;
      this.searchProject();
      const api = projects.model.get;
      this.generateAPI(api).then((res) => {
        this.allProjects = res.data.projects.filter(
          (item) => item._id === this.searchResult
        );
      });
    }
  },
  methods: {
    submitHandler() {
      this.performAction = true;
      const api = { ...sliders.model.update };
      api.data = this.values;
      api.url += this.$route.query.id;
      this.generateAPI(api)
        .then(() => {
          this.performAction = false;
          this.$bvToast.toast("URL updated Successfully!", {
            title: "Slider",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.performAction = false;
          this.$bvToast.toast("Failed to update URL!", {
            title: "Slider",
            variant: "danger",
            solid: true,
          });
        });
    },

    updateImage(data) {
      if (data.image && data.image.length > 0) {
        this.updatingImage = true;
        const api = { ...sliders.model.update };
        api.url += this.$route.query.id;
        api.data = {
          image: data.image[0].location,
        };
        this.generateAPI(api)
          .then((res) => {
            this.$bvToast.toast("Image updated Successfully!", {
            title: "Slider",
            variant: "success",
            solid: true,
          });
          this.values.image = res.data.slider.image
          })
          .catch(() => {
            this.$bvToast.toast("Failed to update image!", {
            title: "Slider",
            variant: "danger",
            solid: true,
          });
          })
          .finally(() => {
            this.updatingImage = false;
          });
      }
    },
    searchProject() {
      this.loading = true;
      const api = sliders.model.get;
      this.generateAPI(api)
        .then((res) => {
          this.searchResult = res.data.sliders.filter(
            (item) => item._id === this.projectNumber
          );
          this.values = { ...this.searchResult[0] };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSlider() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure, you want to delete this slider?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            const api = sliders.model.delete;
            api.id = this.$route.query.id;
            this.generateAPI(api)
              .then(() => {
                
                this.$router.push({ name: "Sliders" });
                this.loading = false;
              })
              .catch((err) => {
                this.$bvToast.toast("Slider is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
              })
              .finally(async () => {
                this.$bvToast.toast("Slider deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.project-images {
  height: 200px;
  width: 200px;
  object-fit: contain;
}
</style>